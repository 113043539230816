// builds/browser.ts
import { createXhrRequester } from "@algolia/requester-browser-xhr";
import { createBrowserLocalStorageCache, createFallbackableCache, createMemoryCache, createNullLogger } from "@algolia/client-common";

// src/analyticsClient.ts
import { createAuth, createTransporter, getAlgoliaAgent } from "@algolia/client-common";
var apiClientVersion = "5.20.3";
var REGIONS = ["de", "us"];
function getDefaultHosts(region) {
  const url = !region ? "analytics.algolia.com" : "analytics.{region}.algolia.com".replace("{region}", region);
  return [{
    url,
    accept: "readWrite",
    protocol: "https"
  }];
}
function createAnalyticsClient({
  appId: appIdOption,
  apiKey: apiKeyOption,
  authMode,
  algoliaAgents,
  region: regionOption,
  ...options
}) {
  const auth = createAuth(appIdOption, apiKeyOption, authMode);
  const transporter = createTransporter({
    hosts: getDefaultHosts(regionOption),
    ...options,
    algoliaAgent: getAlgoliaAgent({
      algoliaAgents,
      client: "Analytics",
      version: apiClientVersion
    }),
    baseHeaders: {
      "content-type": "text/plain",
      ...auth.headers(),
      ...options.baseHeaders
    },
    baseQueryParameters: {
      ...auth.queryParameters(),
      ...options.baseQueryParameters
    }
  });
  return {
    transporter,
    /**
     * The `appId` currently in use.
     */
    appId: appIdOption,
    /**
     * The `apiKey` currently in use.
     */
    apiKey: apiKeyOption,
    /**
     * Clears the cache of the transporter for the `requestsCache` and `responsesCache` properties.
     */
    clearCache() {
      return Promise.all([transporter.requestsCache.clear(), transporter.responsesCache.clear()]).then(() => void 0);
    },
    /**
     * Get the value of the `algoliaAgent`, used by our libraries internally and telemetry system.
     */
    get _ua() {
      return transporter.algoliaAgent.value;
    },
    /**
     * Adds a `segment` to the `x-algolia-agent` sent with every requests.
     *
     * @param segment - The algolia agent (user-agent) segment to add.
     * @param version - The version of the agent.
     */
    addAlgoliaAgent(segment, version) {
      transporter.algoliaAgent.add({
        segment,
        version
      });
    },
    /**
     * Helper method to switch the API key used to authenticate the requests.
     *
     * @param params - Method params.
     * @param params.apiKey - The new API Key to use.
     */
    setClientApiKey({
      apiKey
    }) {
      if (!authMode || authMode === "WithinHeaders") {
        transporter.baseHeaders["x-algolia-api-key"] = apiKey;
      } else {
        transporter.baseQueryParameters["x-algolia-api-key"] = apiKey;
      }
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customDelete - The customDelete object.
     * @param customDelete.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customDelete.parameters - Query parameters to apply to the current query.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customDelete({
      path,
      parameters
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customDelete`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customGet - The customGet object.
     * @param customGet.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customGet.parameters - Query parameters to apply to the current query.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customGet({
      path,
      parameters
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customGet`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customPost - The customPost object.
     * @param customPost.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customPost.parameters - Query parameters to apply to the current query.
     * @param customPost.body - Parameters to send with the custom request.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customPost({
      path,
      parameters,
      body
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customPost`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: body ? body : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customPut - The customPut object.
     * @param customPut.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customPut.parameters - Query parameters to apply to the current query.
     * @param customPut.body - Parameters to send with the custom request.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customPut({
      path,
      parameters,
      body
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customPut`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers,
        data: body ? body : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the add-to-cart rate for all your searches with at least one add-to-cart event, including a daily breakdown.  By default, the analyzed period includes the last eight days including the current day.  The rate is the number of add-to-cart conversion events divided by the number of tracked searches. A search is tracked if it returns a queryID (`clickAnalytics` is `true`). This differs from the response\'s `count`, which shows the overall number of searches, including those where `clickAnalytics` is `false`.  **There\'s a difference between a 0 and null add-to-cart rate when `clickAnalytics` is enabled:**  - **Null** means there were no queries: since Algolia didn\'t receive any events, the add-to-cart rate is null. - **0** mean there _were_ queries but no [add-to-cart events](https://www.algolia.com/doc/guides/sending-events/getting-started/) were received.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getAddToCartRate - The getAddToCartRate object.
     * @param getAddToCartRate.index - Index name.
     * @param getAddToCartRate.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getAddToCartRate.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getAddToCartRate.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getAddToCartRate({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getAddToCartRate`.");
      }
      const requestPath = "/2/conversions/addToCartRate";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the average click position of your search results, including a daily breakdown.  The average click position is the average of all clicked search result positions. For example, if users only ever click on the first result for any search, the average click position is 1. By default, the analyzed period includes the last eight days including the current day.  An average of `null` when `clickAnalytics` is enabled means Algolia didn\'t receive any [click events](https://www.algolia.com/doc/guides/sending-events/getting-started/) for the queries. The average is `null` until Algolia receives at least one click event.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getAverageClickPosition - The getAverageClickPosition object.
     * @param getAverageClickPosition.index - Index name.
     * @param getAverageClickPosition.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getAverageClickPosition.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getAverageClickPosition.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getAverageClickPosition({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getAverageClickPosition`.");
      }
      const requestPath = "/2/clicks/averageClickPosition";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the positions in the search results and their associated number of clicks.  This lets you check how many clicks the first, second, or tenth search results receive.  An average of `0` when `clickAnalytics` is enabled means Algolia didn\'t receive any [click events](https://www.algolia.com/doc/guides/sending-events/getting-started/) for the queries.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getClickPositions - The getClickPositions object.
     * @param getClickPositions.index - Index name.
     * @param getClickPositions.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getClickPositions.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getClickPositions.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getClickPositions({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getClickPositions`.");
      }
      const requestPath = "/2/clicks/positions";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the click-through rate (CTR) for all your searches with at least one click event, including a daily breakdown.  By default, the analyzed period includes the last eight days including the current day.  **There\'s a difference between a 0 and null CTR when `clickAnalytics` is enabled:**  - **Null** means there were no queries: since Algolia didn\'t receive any events, CTR is null. - **0** mean there _were_ queries but no [click events](https://www.algolia.com/doc/guides/sending-events/getting-started/) were received.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getClickThroughRate - The getClickThroughRate object.
     * @param getClickThroughRate.index - Index name.
     * @param getClickThroughRate.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getClickThroughRate.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getClickThroughRate.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getClickThroughRate({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getClickThroughRate`.");
      }
      const requestPath = "/2/clicks/clickThroughRate";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the conversion rate (CR) for all your searches with at least one conversion event, including a daily breakdown.  By default, the analyzed period includes the last eight days including the current day.  **There\'s a difference between a 0 and null CR when `clickAnalytics` is enabled:**  - **Null** means there were no queries: since Algolia didn\'t receive any events, CR is null. - **0** mean there _were_ queries but no [conversion events](https://www.algolia.com/doc/guides/sending-events/getting-started/) were received.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getConversionRate - The getConversionRate object.
     * @param getConversionRate.index - Index name.
     * @param getConversionRate.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getConversionRate.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getConversionRate.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getConversionRate({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getConversionRate`.");
      }
      const requestPath = "/2/conversions/conversionRate";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the fraction of searches that didn\'t lead to any click within a time range, including a daily breakdown. It also returns the number of tracked searches and tracked searches without clicks.  By default, the analyzed period includes the last eight days including the current day.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getNoClickRate - The getNoClickRate object.
     * @param getNoClickRate.index - Index name.
     * @param getNoClickRate.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getNoClickRate.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getNoClickRate.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getNoClickRate({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getNoClickRate`.");
      }
      const requestPath = "/2/searches/noClickRate";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the fraction of searches that didn\'t return any results within a time range, including a daily breakdown. It also returns the count of searches and searches without results used to compute the rates.  By default, the analyzed period includes the last eight days including the current day.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getNoResultsRate - The getNoResultsRate object.
     * @param getNoResultsRate.index - Index name.
     * @param getNoResultsRate.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getNoResultsRate.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getNoResultsRate.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getNoResultsRate({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getNoResultsRate`.");
      }
      const requestPath = "/2/searches/noResultRate";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the purchase rate for all your searches with at least one purchase event, including a daily breakdown.  By default, the analyzed period includes the last eight days including the current day.  The rate is the number of purchase conversion events divided by the number of tracked searches. A search is tracked if it returns a query ID (`clickAnalytics` is `true`). This differs from the response\'s `count`, which shows the overall number of searches, including those where `clickAnalytics` is `false`.  **There\'s a difference between a 0 and null purchase rate when `clickAnalytics` is enabled:**  - **Null** means there were no queries: since Algolia didn\'t receive any events, the purchase rate is null. - **0** mean there _were_ queries but no [purchase conversion events](https://www.algolia.com/doc/guides/sending-events/getting-started/) were received.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getPurchaseRate - The getPurchaseRate object.
     * @param getPurchaseRate.index - Index name.
     * @param getPurchaseRate.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getPurchaseRate.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getPurchaseRate.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getPurchaseRate({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getPurchaseRate`.");
      }
      const requestPath = "/2/conversions/purchaseRate";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves revenue-related metrics, such as the total revenue or the average order value.  To retrieve revenue-related metrics, send purchase events. By default, the analyzed period includes the last eight days including the current day.  Revenue is based on purchase conversion events (a conversion event with an `eventSubtype` attribute of `purchase`). The revenue is the `price` attribute multiplied by the `quantity` attribute for each object in the event\'s `objectData` array.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getRevenue - The getRevenue object.
     * @param getRevenue.index - Index name.
     * @param getRevenue.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getRevenue.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getRevenue.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getRevenue({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getRevenue`.");
      }
      const requestPath = "/2/conversions/revenue";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the number of searches within a time range, including a daily breakdown.  By default, the analyzed period includes the last eight days including the current day.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getSearchesCount - The getSearchesCount object.
     * @param getSearchesCount.index - Index name.
     * @param getSearchesCount.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getSearchesCount.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getSearchesCount.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getSearchesCount({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getSearchesCount`.");
      }
      const requestPath = "/2/searches/count";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the most popular searches that didn\'t lead to any clicks, from the 1,000 most frequent searches.  For each search, it also returns the number of displayed search results that remained unclicked.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getSearchesNoClicks - The getSearchesNoClicks object.
     * @param getSearchesNoClicks.index - Index name.
     * @param getSearchesNoClicks.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getSearchesNoClicks.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getSearchesNoClicks.limit - Number of items to return.
     * @param getSearchesNoClicks.offset - Position of the first item to return.
     * @param getSearchesNoClicks.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getSearchesNoClicks({
      index,
      startDate,
      endDate,
      limit,
      offset,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getSearchesNoClicks`.");
      }
      const requestPath = "/2/searches/noClicks";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the 1,000 most frequent searches that produced zero results.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getSearchesNoResults - The getSearchesNoResults object.
     * @param getSearchesNoResults.index - Index name.
     * @param getSearchesNoResults.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getSearchesNoResults.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getSearchesNoResults.limit - Number of items to return.
     * @param getSearchesNoResults.offset - Position of the first item to return.
     * @param getSearchesNoResults.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getSearchesNoResults({
      index,
      startDate,
      endDate,
      limit,
      offset,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getSearchesNoResults`.");
      }
      const requestPath = "/2/searches/noResults";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the time when the Analytics data for the specified index was last updated.  If the index has been recently created or no search has been performed yet the updated time is `null`.  The Analytics data is updated every 5&nbsp;minutes.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getStatus - The getStatus object.
     * @param getStatus.index - Index name.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getStatus({
      index
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getStatus`.");
      }
      const requestPath = "/2/status";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the countries with the most searches in your index.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getTopCountries - The getTopCountries object.
     * @param getTopCountries.index - Index name.
     * @param getTopCountries.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopCountries.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopCountries.limit - Number of items to return.
     * @param getTopCountries.offset - Position of the first item to return.
     * @param getTopCountries.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTopCountries({
      index,
      startDate,
      endDate,
      limit,
      offset,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getTopCountries`.");
      }
      const requestPath = "/2/countries";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the 1,000 most frequently used filter attributes.  These are attributes of your records that you included in the `attributesForFaceting` setting.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getTopFilterAttributes - The getTopFilterAttributes object.
     * @param getTopFilterAttributes.index - Index name.
     * @param getTopFilterAttributes.search - Search query.
     * @param getTopFilterAttributes.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopFilterAttributes.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopFilterAttributes.limit - Number of items to return.
     * @param getTopFilterAttributes.offset - Position of the first item to return.
     * @param getTopFilterAttributes.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTopFilterAttributes({
      index,
      search,
      startDate,
      endDate,
      limit,
      offset,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getTopFilterAttributes`.");
      }
      const requestPath = "/2/filters";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (search !== void 0) {
        queryParameters["search"] = search.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the 1,000 most frequent filter (facet) values for a filter attribute.  These are attributes of your records that you included in the `attributesForFaceting` setting.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getTopFilterForAttribute - The getTopFilterForAttribute object.
     * @param getTopFilterForAttribute.attribute - Attribute name.
     * @param getTopFilterForAttribute.index - Index name.
     * @param getTopFilterForAttribute.search - Search query.
     * @param getTopFilterForAttribute.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopFilterForAttribute.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopFilterForAttribute.limit - Number of items to return.
     * @param getTopFilterForAttribute.offset - Position of the first item to return.
     * @param getTopFilterForAttribute.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTopFilterForAttribute({
      attribute,
      index,
      search,
      startDate,
      endDate,
      limit,
      offset,
      tags
    }, requestOptions) {
      if (!attribute) {
        throw new Error("Parameter `attribute` is required when calling `getTopFilterForAttribute`.");
      }
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getTopFilterForAttribute`.");
      }
      const requestPath = "/2/filters/{attribute}".replace("{attribute}", encodeURIComponent(attribute));
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (search !== void 0) {
        queryParameters["search"] = search.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the 1,000 most frequently used filters for a search that didn\'t return any results.  To get the most frequent searches without results, use the [Retrieve searches without results](#tag/search/operation/getSearchesNoResults) operation.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getTopFiltersNoResults - The getTopFiltersNoResults object.
     * @param getTopFiltersNoResults.index - Index name.
     * @param getTopFiltersNoResults.search - Search query.
     * @param getTopFiltersNoResults.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopFiltersNoResults.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopFiltersNoResults.limit - Number of items to return.
     * @param getTopFiltersNoResults.offset - Position of the first item to return.
     * @param getTopFiltersNoResults.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTopFiltersNoResults({
      index,
      search,
      startDate,
      endDate,
      limit,
      offset,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getTopFiltersNoResults`.");
      }
      const requestPath = "/2/filters/noResults";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (search !== void 0) {
        queryParameters["search"] = search.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the object IDs of the 1,000 most frequent search results.  If you set the `clickAnalytics` query parameter to true, the response also includes:  - Tracked searches count. Tracked searches are Search API requests with the `clickAnalytics` parameter set to `true`. This differs from the response\'s `count`, which shows the overall number of searches, including those where `clickAnalytics` is `false`. - Click count - Click-through rate (CTR) - Conversion count - Conversion rate (CR) - Average click position  If you set the `revenueAnalytics` parameter to `true`, the response also includes:  - Add-to-cart count - Add-to-cart rate (ATCR) - Purchase count - Purchase rate - Revenue details for each currency  **There\'s a difference between 0% rates and null rates:**  - **Null** means there were no queries: since Algolia didn\'t receive any events, the rates (CTR, CR, ATCR, purchase rate) are null. - **0% rates** mean there _were_ queries but no [click or conversion events](https://www.algolia.com/doc/guides/sending-events/getting-started/) were received.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getTopHits - The getTopHits object.
     * @param getTopHits.index - Index name.
     * @param getTopHits.search - Search query.
     * @param getTopHits.clickAnalytics - Whether to include metrics related to click and conversion events in the response.
     * @param getTopHits.revenueAnalytics - Whether to include metrics related to revenue events in the response.
     * @param getTopHits.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopHits.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopHits.limit - Number of items to return.
     * @param getTopHits.offset - Position of the first item to return.
     * @param getTopHits.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTopHits({
      index,
      search,
      clickAnalytics,
      revenueAnalytics,
      startDate,
      endDate,
      limit,
      offset,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getTopHits`.");
      }
      const requestPath = "/2/hits";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (search !== void 0) {
        queryParameters["search"] = search.toString();
      }
      if (clickAnalytics !== void 0) {
        queryParameters["clickAnalytics"] = clickAnalytics.toString();
      }
      if (revenueAnalytics !== void 0) {
        queryParameters["revenueAnalytics"] = revenueAnalytics.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Returns the most popular searches. For each search, it also includes the average number of hits.  If you set the `clickAnalytics` query parameter to `true`, the response also includes  - Tracked searches count. Tracked searches are Search API requests with the `clickAnalytics` parameter set to `true`. This differs from the response\'s `count`, which shows the overall number of searches, including those where `clickAnalytics` is `false`. - Click count - Click-through rate (CTR) - Conversion count - Conversion rate (CR) - Average click position  If you set the `revenueAnalytics` query parameter to `true`, the response also includes:  - Add-to-cart count - Add-to-cart rate (ATCR) - Purchase count - Purchase rate - Revenue details for each currency  **There\'s a difference between 0% rates and null rates:**  - **Null** means there were no queries: since Algolia didn\'t receive any events, the rates (CTR, CR, ATCR, purchase rate) are null. - **0% rates** mean there _were_ queries but no [click or conversion events](https://www.algolia.com/doc/guides/sending-events/getting-started/) were received.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getTopSearches - The getTopSearches object.
     * @param getTopSearches.index - Index name.
     * @param getTopSearches.clickAnalytics - Whether to include metrics related to click and conversion events in the response.
     * @param getTopSearches.revenueAnalytics - Whether to include metrics related to revenue events in the response.
     * @param getTopSearches.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopSearches.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getTopSearches.orderBy - Attribute by which to order the response items.  If the `clickAnalytics` parameter is false, only `searchCount` is available.
     * @param getTopSearches.direction - Sorting direction of the results: ascending or descending.
     * @param getTopSearches.limit - Number of items to return.
     * @param getTopSearches.offset - Position of the first item to return.
     * @param getTopSearches.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTopSearches({
      index,
      clickAnalytics,
      revenueAnalytics,
      startDate,
      endDate,
      orderBy,
      direction,
      limit,
      offset,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getTopSearches`.");
      }
      const requestPath = "/2/searches";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (clickAnalytics !== void 0) {
        queryParameters["clickAnalytics"] = clickAnalytics.toString();
      }
      if (revenueAnalytics !== void 0) {
        queryParameters["revenueAnalytics"] = revenueAnalytics.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (orderBy !== void 0) {
        queryParameters["orderBy"] = orderBy.toString();
      }
      if (direction !== void 0) {
        queryParameters["direction"] = direction.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the number of unique users within a time range, including a daily breakdown.  Since it returns the number of unique users, the sum of the daily values might be different from the total number.  By default:  - Algolia distinguishes search users by their IP address, _unless_ you include a pseudonymous user identifier in your search requests with the `userToken` API parameter or `x-algolia-usertoken` request header. - The analyzed period includes the last eight days including the current day.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getUsersCount - The getUsersCount object.
     * @param getUsersCount.index - Index name.
     * @param getUsersCount.startDate - Start date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getUsersCount.endDate - End date of the period to analyze, in `YYYY-MM-DD` format.
     * @param getUsersCount.tags - Tags by which to segment the analytics.  You can combine multiple tags with `OR` and `AND`. Tags must be URL-encoded. For more information, see [Segment your analytics data](https://www.algolia.com/doc/guides/search-analytics/guides/segments/).
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getUsersCount({
      index,
      startDate,
      endDate,
      tags
    }, requestOptions) {
      if (!index) {
        throw new Error("Parameter `index` is required when calling `getUsersCount`.");
      }
      const requestPath = "/2/users/count";
      const headers = {};
      const queryParameters = {};
      if (index !== void 0) {
        queryParameters["index"] = index.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      if (tags !== void 0) {
        queryParameters["tags"] = tags.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    }
  };
}

// builds/browser.ts
function analyticsClient(appId, apiKey, region, options) {
  if (!appId || typeof appId !== "string") {
    throw new Error("`appId` is missing.");
  }
  if (!apiKey || typeof apiKey !== "string") {
    throw new Error("`apiKey` is missing.");
  }
  if (region && (typeof region !== "string" || !REGIONS.includes(region))) {
    throw new Error(`\`region\` must be one of the following: ${REGIONS.join(", ")}`);
  }
  return createAnalyticsClient({
    appId,
    apiKey,
    region,
    timeouts: {
      connect: 1e3,
      read: 2e3,
      write: 3e4
    },
    logger: createNullLogger(),
    requester: createXhrRequester(),
    algoliaAgents: [{
      segment: "Browser"
    }],
    authMode: "WithinQueryParameters",
    responsesCache: createMemoryCache(),
    requestsCache: createMemoryCache({
      serializable: false
    }),
    hostsCache: createFallbackableCache({
      caches: [createBrowserLocalStorageCache({
        key: `${apiClientVersion}-${appId}`
      }), createMemoryCache()]
    }),
    ...options
  });
}
export { analyticsClient, apiClientVersion };
